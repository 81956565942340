import { Box, Stack, Typography } from '@mui/material'
import { FC, useEffect, useMemo, useState } from 'react'
import { GRAY_700, GRAY_900 } from 'constants/styling/theme'

import { AssignmentDocumentDTO } from 'models/assignment'
import CampaignIcon from '@mui/icons-material/Campaign'
import CheckCircleOutlineRoundedIcon from '@mui/icons-material/CheckCircleOutlineRounded'
import CloudDownloadOutlinedIcon from '@mui/icons-material/CloudDownloadOutlined'
import { DocumentUploadType } from 'constants/documents'
import DynamicQueryContent from 'components/common/DynamicQueryContent/DynamicQueryContent'
import { FilterTab } from 'components/common/FilterTab'
import JSZip from 'jszip'
import { MUIButton } from 'components/common/MUIButton'
import { MUIDivider } from 'components/common/MUIDivider'
import { Nullable } from 'models/helpers'
import { SectionedBorderBox } from 'components/common/SectionedBorderBox'
import { saveAs } from 'file-saver'
import { useClientGallery } from '../_main/contexts/ClientGallery.context'
import { useGalleryAssignment } from 'components/pages/Gallery/_main/contexts/GalleryAssignment.context'
import { useGetAssignmentDocuments } from 'dataQueries/assignmentDocuments.query'
import { useTranslation } from 'react-i18next'

/**
 * Client gallery documents component
 * 
 * @example
 * <ClientGalleryDocuments />
 */
export const ClientGalleryDocuments: FC = () => {
  const { t } = useTranslation(['gallery'])
  const { assignmentId } = useClientGallery()
  const { isDocumentWithValidateDraft } = useGalleryAssignment()

  const getAssignmentOutputDocuments = useGetAssignmentDocuments(assignmentId, DocumentUploadType.OUTPUT)
  const assignmentDocumentsData = useMemo(() => getAssignmentOutputDocuments.data?.data || [], [getAssignmentOutputDocuments.data])

  const [selectedDoc, setSelectedDoc] = useState<Nullable<AssignmentDocumentDTO>>()

  useEffect(() => {
    if (assignmentDocumentsData.length) {
      setSelectedDoc(assignmentDocumentsData[0])
    }
  }, [assignmentDocumentsData])

  const downloadAllFiles = async () => {
    const zip = new JSZip()
    const folder = zip.folder(`${assignmentId}`)

    for (const doc of assignmentDocumentsData) {
      const response = await fetch(doc.signedUrl.signedURL)
      const blob = await response.blob()
      folder?.file(`${doc.signedUrl.filename}`, blob)
    }

    const content = await zip.generateAsync({ type: 'blob' })
    saveAs(content, `${assignmentId}.zip`)
  }

  return (
    <DynamicQueryContent query={getAssignmentOutputDocuments}>

      {isDocumentWithValidateDraft &&
        <Stack width="100%" marginTop="3rem" gap="0.8rem" justifyContent="space-between">

          <Typography variant="text-lg" fontWeight={600} color={GRAY_900}>{t('client_gallery.review_draft')}</Typography>

          <Stack width="100%" flexDirection="row" justifyContent="space-between" alignItems="flex-end">

            <Box maxWidth="56rem">
              <Typography variant="text-sm" fontWeight={400} color={GRAY_700}>{t('client_gallery.review_draft_note')}</Typography>
            </Box>

            <Stack gap="0.8rem" flexDirection="row">
              <MUIButton
                type="secondaryNoBorder"
                size="sm"
                startIcon={<CampaignIcon />}
                onClick={() => { }}
              >
                {t('send_feedback')}
              </MUIButton>

              <MUIButton
                type="defaultPrimary"
                size="sm"
                startIcon={<CheckCircleOutlineRoundedIcon />}
                onClick={() => { }}
              >
                {t('client_gallery.validate_document')}
              </MUIButton>
            </Stack>

          </Stack>

        </Stack>
      }

      <Stack width="100%" marginTop="3rem" gap={4} display="flex" flexDirection="row" justifyContent="space-between">

        {assignmentDocumentsData.length > 1 &&
          <Box height="100%" flex="1 0 300px" position="sticky">
            <SectionedBorderBox title={t('provided_files')}>

              <Stack gap="1rem">
                {assignmentDocumentsData.map((doc, index) => {
                  return (
                    <FilterTab
                      key={doc.name}
                      isActive={selectedDoc?.name === doc.name}
                      height='4rem'
                      label={doc.signedUrl.filename}
                      onClick={() => setSelectedDoc(doc)}
                    />
                  )
                })}

              </Stack>

              <MUIDivider margin={12} />

              <MUIButton
                fullWidth
                type="secondaryBorder"
                size="md"
                startIcon={<CloudDownloadOutlinedIcon fontSize="medium" />}
                onClick={downloadAllFiles}
              >
                {t('download_all_files')}
              </MUIButton>

            </SectionedBorderBox>
          </Box>
        }

        {assignmentDocumentsData.length &&
          <Stack flex="1 1 75%">
            <iframe
              src={selectedDoc?.signedUrl.signedURL}
              width="100%"
              height="600px"
              style={{ border: 'none' }}
              title={t('pdf_preview')}
            />
          </Stack>
        }

      </Stack>

    </DynamicQueryContent>
  )
}
