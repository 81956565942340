/** Enum listing all possible states of creative in relation to assignment */
export enum CreativeState {
  ACCEPTED = 'ACCEPTED',
  UNASSIGNED = 'UNASSIGNED',
  WAITING_FOR_ACCEPT = 'WAITING_FOR_ACCEPT',
}

/** Enumeration of available pipedrive assignment stages */
export enum AssignmentStage {
  MISSION_ORDER_PLACED = 'MISSION_ORDER_PLACED',
  PRE_PRODUCTION = 'PRE_PRODUCTION',
  AUTOMATED = 'AUTOMATED',
  WAITING_FOR_CT_TO_ACCEPT = 'WAITING_FOR_CT_TO_ACCEPT',
  WAITING_FOR_DATE_AND_TIME = 'WAITING_FOR_DATE_AND_TIME',
  CREATIVE_BOOKED = 'CREATIVE_BOOKED',
  VISUALS_SENT_BY_CT_TO_BKBN = 'VISUALS_SENT_BY_CT_TO_BKBN',
  VISUALS_SENT_BY_BKBN_TO_EDITOR = 'VISUALS_SENT_BY_BKBN_TO_EDITOR',
  VISUALS_SENT_BY_EDITOR_TO_BKBN = 'VISUALS_SENT_BY_EDITOR_TO_BKBN',
  VISUALS_SENT_TO_CLIENT = 'VISUALS_SENT_TO_CLIENT',
  WAITING_FOR_POWER_OF_ATTORNEY_DOCUMENTS = 'WAITING_FOR_POWER_OF_ATTORNEY_DOCUMENTS',
  WAITING_FOR_CLIENT_TO_UPLOAD_DOCUMENTS = 'WAITING_FOR_CLIENT_TO_UPLOAD_DOCUMENTS', // After the client has uploaded the documents and supplier requested more documents
  REQUEST_SENT_TO_PROCESSOR = 'REQUEST_SENT_TO_PROCESSOR',
  DOCUMENTS_RECEIVED_FROM_PROCESSOR = 'DOCUMENTS_RECEIVED_FROM_PROCESSOR',
  DOCUMENTS_SENT_TO_CLIENT = 'DOCUMENTS_SENT_TO_CLIENT',
}

/** Enumeration of available assignment job status */
export enum AssignmentJob {
  ORDER_RECEIVED = 'order_received',
  CREATIVE_BOOKED = 'creative_booked',
  SHOOTING_PERFORMED = 'shooting_performed',
  ORDER_DELIVERED = 'order_delivered',
  MATCHING_CREATIVE = 'matching_creative',
  WAITING_FOR_SHOOTING = 'waiting_for_shooting',
  EDITING_VISUALS = 'editing_visuals',
  WAITING_FOR_SELECTION = 'waiting_for_selection',
  SELECTION_DONE = 'selection_done',
  CHECKING_DOCUMENT = 'checking_document',
  AWAITING_SIGNATURE = 'awaiting_signature',
  DOCUMENT_SIGNED = 'documents_signed',
  DOCUMENTS_IN_PROGRESS = 'documents_in_progress',
  VALIDATE_DRAFT = 'validate_draft',
}

/** Enumeration of available card colors */
export enum CardColor {
  BLUE = 'blue',
  ORANGE = 'orange',
  GREEN = 'green',
  RED = 'red'
}
